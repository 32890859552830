<template>
    <app-wrapper>
        <template v-slot:child>
            <!-- Top content-->

            <template v-if="isLoading"><Spinner /></template>

            <div class="flex flex-wrap justify-between items-center w-90 center pv4-l pv3 mini-spacing">
                <div class="pb3 pb0-l">
                    <div class="f3 b">Set up your Invoice Template</div>
                    <div class="dashboard-inquiry-modal" v-if="clickModalInvoice">
                        How does this work? Click <a href="#" target="_blank" style="color: blue">here</a> to learn more.
                    </div>
                </div>
            </div>
            <div class="w-100-l w-90 center pv4-l pv3 mini-spacing">
                <div class="flex flex-wrap justify-between w-90 center">
                    <div class="w-30-l w-100">
                        <div class="box-border" style="background: #fbfcfe">
                            <div class="flex pa3 justify-between items-center">
                                <div class="w-100 b">Customize Invoice</div>
                            </div>
                            <div class="pa3">
                                <div
                                    class="flex flex-wrap justify-between items-center mt3"
                                    @drop.prevent="handleDragImage"
                                    @dragover.prevent
                                    @dragenter.prevent
                                >
                                    <div class="w-30-l w-100 pb3 tc b f4 pb0-l pointer" @click.prevent="handleClickUpload">
                                        <img
                                            :src="
                                                state?.image ||
                                                state?.imageUrl ||
                                                orgImage ||
                                                require('@/assets/images/profile-pic.svg')
                                            "
                                            alt="Org Image"
                                            class="h-60-l w-60-l h-60 w-60 rounded-circle"
                                            style="object-position: center; object-fit: cover; width: 60px; height: 60px"
                                        />
                                    </div>
                                    <div class="box-border pa2 w-70-l w-100">
                                        <span class="dn">
                                            <input
                                                ref="imageElRef"
                                                type="file"
                                                name="imageFile"
                                                id="imageFile"
                                                accept="image/*"
                                                @change.prevent="handleSelectImage"
                                            />
                                        </span>
                                        <span
                                            class="font-w1 lh-copy pointer"
                                            style="color: #132c8c"
                                            @click.prevent="handleClickUpload"
                                        >
                                            Upload
                                        </span>
                                        or drag and drop your company logo PNG, JPG, GIF up to 10MB
                                    </div>
                                </div>
                            </div>
                            <div class="ph3">
                                <div class="flex flex-column pb3">
                                    <div class="flex flex-column pt1">
                                        <label class="pb2 fw5">Company Name </label>
                                        <input
                                            class="pa2 box-border"
                                            type="text"
                                            name=""
                                            placeholder="Simplebks Ltd"
                                            id=""
                                            v-model="state.invoice.business.name"
                                            required
                                        />
                                    </div>

                                    <div class="flex flex-column pt2">
                                        <label class="pb2 fw5">Address</label>
                                        <input
                                            class="pa2 box-border"
                                            type="text"
                                            name=""
                                            placeholder="No 10, Ajayi Estate , Ikeja, Lagos"
                                            id=""
                                            v-model="state.invoice.business.address"
                                        />
                                    </div>

                                    <div class="flex flex-column pt2">
                                        <label class="pb2 fw5">Country</label>
                                        <!-- <input
                                            class="pa2 box-border"
                                            type="text"
                                            name=""
                                            placeholder="Nigeria"
                                            id=""
                                            v-model="state.invoice.business.country"
                                        /> -->
                                        <select
                                            class="pa2 box-border"
                                            id="country"
                                            name="country"
                                            @change="(value) => onNameChange(value, 'country')"
                                        >
                                            <option value="" selected>Choose Country</option>
                                            <option
                                                v-for="country in sortedCountries"
                                                :key="country.alpha2Code"
                                                :value="country.name.common"
                                            >
                                                {{ country.name.common }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="flex flex-column pt2">
                                        <label class="pb2 fw5">Phone</label>
                                        <input
                                            class="pa2 box-border"
                                            type="text"
                                            name=""
                                            placeholder="0703XXXXXXX"
                                            id=""
                                            v-model="state.invoice.business.phone"
                                        />
                                    </div>

                                    <div class="flex flex-column pt2">
                                        <label class="pb2 fw5">Email</label>
                                        <input
                                            class="pa2 box-border"
                                            type="email"
                                            name=""
                                            placeholder="business@example.com"
                                            id=""
                                            v-model="state.invoice.business.email"
                                        />
                                    </div>

                                    <div class="flex flex-column pt2">
                                        <label class="pb2 fw5">TIN</label>
                                        <input
                                            class="pa2 box-border"
                                            type="text"
                                            name=""
                                            placeholder="123456789"
                                            id=""
                                            v-model="state.invoice.business.tin"
                                        />
                                    </div>

                                    <div class="flex flex-column pt2">
                                        <label class="pb2 fw5">RC No.</label>
                                        <input
                                            class="pa2 box-border"
                                            type="text"
                                            name=""
                                            placeholder="123456789"
                                            id=""
                                            v-model="state.invoice.business.rcNumber"
                                        />
                                    </div>

                                    <div class="flex flex-column pt2">
                                        <label class="pb2 fw5">Color</label>
                                        <ColorPicker
                                            theme="light"
                                            :color="color"
                                            :sucker-hide="false"
                                            :sucker-canvas="suckerCanvas"
                                            :sucker-area="suckerArea"
                                            @changeColor="changeColor"
                                            @openSucker="openSucker"
                                        />
                                    </div>

                                    <div class="flex flex-column pt2">
                                        <label class="pb2 fw5">Font</label>
                                        <select v-model="state.font">
                                            <option v-for="font in state.fonts" :key="font" :value="font">
                                                {{ font }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="flex flex-column pt2">
                                        <label class="pb2 fw5">Template</label>
                                        <div class="flex flex-wrap items-center justify-between">
                                            <button
                                                v-for="template in state.templates"
                                                :key="template"
                                                :class="
                                                    state.invoice_template === template ? 'btn4 fw5 pa2 mb2' : 'btn fw5 pa2 mb2'
                                                "
                                                @click="selectTemplate(template)"
                                            >
                                                {{ template }}
                                            </button>
                                        </div>
                                        <!-- <select name="template" v-model="state.invoice_template">
                                            <option value="default">Default Template</option>
                                            <option value="letterhead">Letterhead Template</option>
                                        </select> -->
                                    </div>

                                    <div class="flex justify-between items-center pt2">
                                        <button class="btn2" @click="skipCustomize">Skip</button>
                                        <button @click="submit">Save & Continue</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="state.invoice.status === 'draft'" class="box-border mt4" style="background: #fbfcfe">
                            <div class="pa3">
                                <p class="box-border pa3">
                                    This invoice is a draft. You can edit it and send it to your customer.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="w-60-l w-100 mb4 mb0-l">
                        <div v-if="state.invoice_template == 'default'" ref="invoiceFormRef">
                            <div class="box-border pv4" style="background: #fbfcfe">
                                <div class="w-90 center pa3-l pa2 box-border" style="background: white">
                                    <div class="flex flex-wrap justify-between pv3">
                                        <div class="font-w1 pb3 pb0-l">Invoice No {{ state.invoice.number }}</div>
                                        <div class="flex">
                                            <div>Date issued: {{ formatDate(state.invoice.dateIssued) }}</div>
                                            <div class="pl3">Date due: {{ formatDate(state.invoice.dateDue) }}</div>
                                            <div v-if="state.invoice?.last_payment_date">
                                                Last payment date: {{ formatDate(state.invoice.last_payment_date) }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pa3" style="background: #f7f9fd">
                                        <div class="flex items-center justify-between pb2">
                                            <div>
                                                <div class="f3 b pb2">
                                                    Invoice
                                                    <invoice-status :invoice="state.invoice" />
                                                </div>
                                            </div>
                                            <div class="db-l dn">
                                                <img src="imgs/BA-large.svg" alt="" />
                                            </div>
                                        </div>
                                        <div class="flex flex-wrap justify-between bill-section">
                                            <div class="pv3">
                                                <div class="b pb2" style="color: #132c8c">Bill to</div>
                                                <div>
                                                    <div class="pb2 b">{{ state.invoice.customer?.name }}</div>
                                                    <div class="pb2">{{ state.invoice.customer?.address }}</div>
                                                    <div class="pb2">{{ state.invoice.customer?.email }}</div>
                                                    <div class="pb2">{{ state.invoice.customer?.phone }}</div>
                                                </div>
                                            </div>
                                            <div class="pv3 tr-lx tl">
                                                <div class="b pb2" style="color: #132c8c">Bill from</div>
                                                <div class="flex justify-center items-center">
                                                    <div ref="companyLogoWrapper" v-if="orgImage">
                                                        <img
                                                            ref="companyLogo"
                                                            :src="orgImage"
                                                            alt=""
                                                            style="
                                                                object-position: center;
                                                                object-fit: cover;
                                                                width: 60px;
                                                                height: 60px;
                                                            "
                                                            class="rounded-circle"
                                                        />
                                                    </div>
                                                    <div v-else class="org-img-placeholder">
                                                        <span>{{ orgInitials || userInitials }}</span>
                                                    </div>
                                                    <div>
                                                        <div class="pb2x b">{{ state.invoice.business?.name }}</div>
                                                        <div class="pb2">{{ state.invoice.business?.address }}</div>
                                                        <div class="pb2">{{ state.invoice.business?.country }}</div>
                                                        <div class="pb2">{{ state.invoice.business?.email }}</div>
                                                        <div class="pb2">{{ state.invoice.business?.phone }}</div>
                                                        <div v-if="state.invoice.business?.tin" class="pb2">
                                                            TIN: {{ state.invoice.business?.tin }}
                                                        </div>
                                                        <div v-if="state.invoice.business?.tin" class="pb2">
                                                            RC No: {{ state.invoice.business?.rcNumber }}
                                                        </div>
                                                    </div>
                                                    <div
                                                        v-if="state.invoice?.description && state.invoice.description != ''"
                                                        class="mt3 b"
                                                    >
                                                        {{ state.invoice.description }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pa3-l pa2">
                                        <div class="flex justify-between pv3 box-border-bottom font-w1">
                                            <div class="w-30">Item</div>
                                            <div class="w-60 flex justify-between">
                                                <div class="w-30 tl">Cost</div>
                                                <div class="w-30 tc">Qty/Unit</div>
                                                <div class="w-30 tr">Price</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-for="item of state.invoice.items" v-bind:key="item.id" class="pa3-l pa2">
                                        <div class="flex justify-between pb3 box-border-bottom">
                                            <div class="w-30">
                                                <div class="b">{{ item.name }}</div>
                                                <div v-if="item.notes" class="text-muted" style="font-size: 0.9em">
                                                    {{ item.notes }}
                                                </div>
                                            </div>
                                            <div class="w-60 flex justify-between">
                                                <div class="w-30 tl">{{ item.price }}</div>
                                                <div class="w-30 tc">{{ item.quantity }} {{ item.measurement }}</div>
                                                <div class="w-30 tr">
                                                    <div v-if="item.discount > 0">
                                                        <div class="text-muted" style="text-decoration: line-through">
                                                            {{
                                                                formatAmount(
                                                                    item.price * item.quantity,
                                                                    state.invoice.currency || $store.state.Settings?.currency
                                                                )
                                                            }}
                                                        </div>
                                                        <div>
                                                            {{
                                                                formatAmount(
                                                                    item.total,
                                                                    state.invoice.currency || $store.state.Settings?.currency
                                                                )
                                                            }}
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        {{
                                                            formatAmount(
                                                                item.price * item.quantity,
                                                                state.invoice.currency || $store.state.Settings?.currency
                                                            )
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Subtotal -->
                                    <div class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                                <div class="w-30 tl">Subtotal</div>
                                                <div class="w-30 tr">
                                                    {{
                                                        formatAmount(
                                                            state.invoice.subtotal,
                                                            state.invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Discount -->
                                    <div v-if="state.invoice.hasDiscount" class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                                <div class="w-30 tl">Discount</div>
                                                <div v-if="state.invoice.discountType == 'fixed'" class="w-30 tr">
                                                    {{
                                                        formatAmount(
                                                            state.invoice.discount,
                                                            state.invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                                <div v-else class="w-30 tr">{{ state.invoice.discount }}%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="state.invoice.hasCommission" class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                                <div class="w-30 tl">Commission</div>
                                                <div v-if="state.invoice.commissionType == 'fixed'" class="w-30 tr">
                                                    {{
                                                        formatAmount(
                                                            state.invoice.commission,
                                                            state.invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                                <div v-else class="w-30 tr">{{ state.invoice.commission }}%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="state.invoice.hasShipping" class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                                <div class="w-30 tl">Shipping</div>
                                                <div v-if="state.invoice.shippingType == 'fixed'" class="w-30 tr">
                                                    {{
                                                        formatAmount(
                                                            state.invoice.shipping,
                                                            state.invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                                <div v-else class="w-30 tr">{{ state.invoice.shipping }}%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="state.invoice.hasTax" class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                                <div class="w-30 tl">Tax</div>
                                                <div v-if="state.invoice.taxType == 'fixed'" class="w-30 tr">
                                                    {{
                                                        formatAmount(
                                                            state.invoice.tax,
                                                            state.invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                                <div v-else class="w-30 tr">{{ state.invoice.tax }}%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="state.invoice.hasOthersCharges" class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                                <div class="w-30 tl">{{ state.invoice.others }}</div>
                                                <div class="w-30 tr">
                                                    {{
                                                        formatAmount(
                                                            state.invoice.othersCharges,
                                                            state.invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="ph3-l ph2">
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                                <div class="w-30 tl">Total</div>
                                                <div class="w-30 tr b" style="color: #132c8c">
                                                    {{
                                                        formatAmount(
                                                            state.invoice.totalAmount,
                                                            state.invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        v-if="
                                            state.invoice.status?.includes('part_payment') ||
                                            state.invoice.status?.includes('paid')
                                        "
                                        class="ph3-l ph2"
                                    >
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom b">
                                                <div class="w-30 tl">Amount Paid</div>
                                                <div class="w-30 tr" style="color: #132c8c">
                                                    {{
                                                        formatAmount(
                                                            state.invoice?.deposit || 0,
                                                            state.invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        v-if="
                                            state.invoice.status?.includes('part_payment') ||
                                            state.invoice.status?.includes('paid')
                                        "
                                        class="ph3-l ph2"
                                    >
                                        <div class="flex justify-between pb3 font-w1">
                                            <div class="w-30-l"></div>
                                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom b">
                                                <div class="w-30 tl text-danger">Outstanding</div>
                                                <div class="w-30 tr text-danger">
                                                    {{
                                                        formatAmount(
                                                            state.invoice.totalAmount - (state.invoice?.deposit || 0),
                                                            state.invoice.currency || $store.state.Settings?.currency
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="lh-copy pv3"
                                        style="border-top: 1px solid #e3e8ee; border-bottom: 1px solid #e3e8ee"
                                    >
                                        All amounts are in {{ getCurrencyName(state.invoice.currency) }}. Please make the payment
                                        before {{ formatDate(state.invoice.dateDue) }}.
                                    </div>
                                    <div
                                        v-if="state.invoice.notes"
                                        class="lh-copy pv3"
                                        style="border-top: 1px solid #e3e8ee; border-bottom: 1px solid #e3e8ee"
                                    >
                                        <div class="font-w1">Notes:</div>
                                        {{ state.invoice.notes ? state.invoice.notes : '' }}
                                    </div>
                                </div>
                            </div>

                            <div v-if="state.invoice?.paymentInfo" class="box-border mv4" style="background: #fbfcfe">
                                <div class="flex justify-between box-border-bottom pa3">
                                    <div>Payment Info</div>
                                </div>
                                <div
                                    v-for="(data, index) of state.invoice.paymentInfo"
                                    v-bind:key="index"
                                    class="pa3"
                                    style="border-bottom: 1px solid #e3e8ee"
                                >
                                    <div class="flex justify-between">
                                        <div class="w-30">{{ data.account }}</div>
                                        <div class="w-50 b">
                                            {{
                                                formatAmount(
                                                    data.amount,
                                                    state.invoice.currency || $store.state.Settings?.currency
                                                )
                                            }}
                                        </div>
                                        <div class="w-20 b">{{ data?.date ? formatDate(data.date) : '' }}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="box-border mv4" style="background: #fbfcfe">
                                <div class="flex justify-between box-border-bottom pa3">
                                    <div>Selected payment option</div>
                                </div>
                                <div v-for="item of state.invoice.bankDetails" v-bind:key="item.bankAccountNumber" class="pa3">
                                    <div class="flex flex-wrap justify-between items-center pa3 box-border">
                                        <div>
                                            <div class="pb2 b">Bank Transfer</div>
                                            <div class="pb2">Pay by making a bank transfer to the recievers bank account</div>
                                            <div class="flex justify-between">
                                                <div class="w-30">Bank Name:</div>
                                                <div class="w-70 b">{{ item.bankName }}</div>
                                            </div>
                                            <div class="flex justify-between">
                                                <div class="w-30">Account Number:</div>
                                                <div class="w-70 b">{{ item.bankAccountNumber }}</div>
                                            </div>
                                            <div class="flex justify-between">
                                                <div class="w-30">Account Name:</div>
                                                <div class="w-70 b">{{ item.bankAccountName }}</div>
                                            </div>
                                        </div>
                                        <div class="pt0-l pt3">
                                            <img src="imgs/bank.svg" alt="" />
                                        </div>
                                    </div>
                                </div>

                                <div v-if="state.invoice?.paystackLink" class="pa3">
                                    <div class="flex flex-wrap justify-between items-center pa3 box-border copy-text-wrapper">
                                        <div>
                                            <div class="pb2 b">Paystack</div>
                                            <div>Pay through the secure paystack API</div>
                                            <div class="flex flex-wrap justify-between items-center pb2">
                                                <a :href="state.invoice.paystackLink" target="_blank" class="pr2">{{
                                                    state.invoice.paystackLink
                                                }}</a>
                                                <button @click="doCopy" class="pl0 pr0 pb0 pt0">
                                                    <span class="icon">
                                                        <font-awesome-icon icon="fa-solid fa-copy" />
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="pt0-l pt3">
                                            <img :src="require('@/assets/images/paystack.svg')" alt="paystack logo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <GoldInvoice
                            v-else-if="state.invoice_template == 'gold'"
                            :invoice="state.invoice"
                            :color="state.color"
                            :font="state.font"
                        />
                        <SilverInvoice
                            v-else-if="state.invoice_template == 'silver'"
                            :invoice="state.invoice"
                            :color="state.color"
                            :font="state.font"
                        />
                        <PlatinumInvoice
                            v-else-if="state.invoice_template == 'platinum'"
                            :invoice="state.invoice"
                            :color="state.color"
                            :font="state.font"
                        />
                        <DiamondInvoice
                            v-else-if="state.invoice_template == 'diamond'"
                            :invoice="state.invoice"
                            :color="state.color"
                            :font="state.font"
                        />
                        <letter-head-invoice v-else :invoice="state.invoice" :color="state.color" :font="state.font" />
                    </div>
                </div>
            </div>
        </template>
    </app-wrapper>
</template>

<script>
import { onMounted, computed, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { ColorPicker } from 'vue-color-kit'
import 'vue-color-kit/dist/vue-color-kit.css'
import axios from 'axios'
import { formatAmount, formatQuantity, getCurrencyName, formatDate, getDaysFromToday } from '@/utils/lib'
import AppWrapper from '@/layout/AppWrapper'
import InvoiceHeader from '@/views/Invoice/widgets/InvoiceHeader'
import Spinner from '@/components/Spinner'
import LetterHeadInvoice from '../../components/LetterHeadInvoice.vue'
import DiamondInvoice from '../../components/DiamondInvoice.vue'
import GoldInvoice from '../../components/GoldInvoice.vue'
import SilverInvoice from '../../components/SilverInvoice.vue'
import PlatinumInvoice from '../../components/PlatinumInvoice.vue'

export default {
    name: 'InvoiceView',
    components: {
        ColorPicker,
        AppWrapper,
        InvoiceHeader,
        Spinner,
        LetterHeadInvoice,
        DiamondInvoice,
        GoldInvoice,
        SilverInvoice,
        PlatinumInvoice,
    },

    setup() {
        const router = useRouter()
        const store = useStore()

        const invoiceFormRef = ref(null)
        const imageElRef = ref(null)

        const isLoading = computed(() => store?.state.Invoice.isLoading)
        const orgData = computed(() => store.state?.Auth?.userData)
        const orgInitials = computed(() => store?.state?.Auth?.orgInitials)
        const orgImage = computed(() => store?.state?.Auth?.orgImage)
        const route = useRoute()

        const countries = ref([])
        const sortedCountries = ref([])
        const selectedCountry = ref(null)

        const allCountries = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all')
                countries.value = response.data
            } catch (error) {
                console.error('Error fetching countries:', error)
            }
        }
        const sortCountriesAlphabetically = () => {
            sortedCountries.value = [...countries.value].sort((a, b) => a.name.common.localeCompare(b.name.common))
        }

        // Watch for changes in countries and sort when updated
        watch(countries, () => {
            sortCountriesAlphabetically()
        })

        const initialState = {
            isLoading: true,
            invoiceId: route.params.id,
            imageFile: '',
            image: '',
            imageUrl: '',
            invoice_template: 'letterhead',
            invoice: {
                _id: '645df0db2c34aa99aa74deb5',
                dateIssued: '2023-05-11',
                dateDue: '2023-05-18',
                description: '',
                paystackSlug: '',
                paystackLink: '',
                reminder: false,
                customer: {
                    id: '645ca5de5efe8105f2b5dfa7',
                    name: 'Adams Collins',
                    email: 'ifeanyi26@simplebks.com',
                    phone: '4855994399',
                    city: '',
                    state: '',
                    zip: '',
                    country: '',
                    vat_number: '',
                    tax_number: '',
                    website: '',
                },
                items: [
                    {
                        name: 'Beans',
                        price: 8000,
                        quantity: 1,
                        instock: 263,
                        discount: 0,
                        discountType: 'percentage',
                        showDiscount: false,
                        notes: '',
                        showNote: false,
                        total: 8000,
                        productDropDown: false,
                        sku: 'AC-002',
                        id: '62bf0389afc624457d7fb4e9',
                        type: 'PRODUCT',
                    },
                ],
                notes: '',
                subtotal: 8000,
                discount: 0,
                tax: 0,
                totalAmount: 8000,
                totalAfterDiscount: 8000,
                currency: 'EUR',
                bankDetails: [
                    {
                        bankAccountName: 'ABC Consult',
                        bankAccountNumber: '0112543456',
                        bankName: 'First Bank',
                        isDefault: 'true',
                        sortCode: '',
                    },
                    {
                        bankAccountName: 'ABC Consult',
                        bankAccountNumber: '1234567890',
                        bankName: 'GTB',
                        aliasName: 'Alias 1',
                        sortCode: '65678',
                        isDefault: false,
                    },
                ],
                shipping: 0,
                commission: 0,
                othersCharges: 0,
                others: '',
                hasTax: false,
                hasDiscount: false,
                hasShipping: false,
                hasCommission: false,
                hasOthersCharges: false,
                IsRecurring: false,
                recurringType: '',
                maxNumberOfPayments: '',
                business: {
                    name: '',
                    email: '',
                    phone: '',
                    address: '',
                    country: '',
                    tin: '',
                    rcNumber: '',
                },
                discountType: 'percentage',
                commissionType: 'fixed',
                taxType: 'percentage',
                shippingType: 'fixed',
                IsCustomerSelected: true,
                date: '2023-05-11',
                initial_deposit: 0,
                glAccounts: [{ glAccount: 'Cash', glAmount: 0 }],
                organaizationId: '62b9914c1e1dc38039e5739e',
                staffId: '62b990fa1e1dc38039e5739d',
                staffName: 'ifeanyi Nnorom',
                createdAt: '2023-05-12T07:55:07.098Z',
                updatedAt: '2023-05-12T07:55:07.098Z',
                status: 'awaiting_payment',
                number: 'ACD0138',
                reference: 'eo52kK',
            },
            color: '#132C8C',
            suckerCanvas: null,
            suckerArea: [],
            isSucking: false,
            font: 'Arial',
            fonts: {
                arial: 'Arial',
                century: 'Century',
                verdana: 'Verdana',
                times_new_roman: 'Times New Roman',
                helvetica: 'Helvetica',
            },
            templates: {
                default: 'default',
                letterhead: 'letterhead',
                diamond: 'diamond',
                gold: 'gold',
                silver: 'silver',
                platinum: 'platinum',
            },
        }

        const state = reactive(initialState)

        const clickModalInvoice = ref(false)

        const closeModal = () => {
            clickModalInvoice.value = false
        }

        const handleClickUpload = () => {
            // eslint-disable-next-line
            imageElRef?.value?.click()
        }

        const handleSelectImage = (e) => {
            if (e?.target?.files?.length) {
                const file = e.target.files[0]
                // eslint-disable-next-line prefer-destructuring
                state.imageFile = e.target.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    state.imageFile = file
                    state.imageUrl = e.target.result

                    if (reader.readyState === 2) {
                        state.image = reader.result.toString()
                    }
                }
                reader.readAsDataURL(file)
            }
        }

        const handleDragImage = (e) => {
            if (e?.dataTransfer?.files?.length) {
                const file = e.dataTransfer.files[0]
                // eslint-disable-next-line prefer-destructuring
                state.imageFile = e.dataTransfer.files[0]
                const reader = new FileReader()
                reader.onload = (e) => {
                    state.imageFile = file
                    state.imageUrl = e.target.result

                    if (reader.readyState === 2) {
                        state.image = reader.result.toString()
                    }
                }
                reader.readAsDataURL(file)
            }
        }

        const changeColor = (color) => {
            // const { r, g, b, a } = color.rgba
            // state.color = `rgba(${r}, ${g}, ${b}, ${a})`
            state.color = color.hex
        }

        const openSucker = (isOpen) => {
            if (isOpen) {
                // ... canvas be created
                // this.suckerCanvas = canvas
                // this.suckerArea = [x1, y1, x2, y2]
            } else {
                // this.suckerCanvas && this.suckerCanvas.remove
            }
        }

        const selectTemplate = (value) => {
            state.invoice_template = value
        }

        const skipCustomize = () => {
            router.push({ name: 'InvoiceNew', query: { skipCustomize: true } })
        }

        const submit = () => {
            const { p, id } = route.query
            const query = { p, id }

            const payload = {
                invoice: {
                    color: state.color,
                    font: state.font,
                    template: state.invoice_template,
                },
                business: {
                    name: state.invoice.business.name,
                    address: state.invoice.business.address,
                    country: state.invoice.business.country,
                    phoneNumber: state.invoice.business.phone,
                    orgEmail: state.invoice.business.email,
                    tin: state.invoice.business.tin,
                    rcNumber: state.invoice.business.rcNumber,
                },
            }

            store.dispatch('Settings/updateInvoiceCustomization', { payload, query })
        }

        watch(
            () => orgData.value,
            (newValue, oldValue) => {
                state.invoice.business.name = newValue?.org || state.invoice.business.name
                state.invoice.business.address = newValue?.address || state.invoice.business.address
                state.invoice.business.phone = newValue?.phone || state.invoice.business.phone
                state.invoice.business.email = newValue?.email || state.invoice.business.email
                state.invoice.business.country = newValue?.country || state.invoice.business.country
                state.invoice.business.tin = newValue?.tin || state.invoice.business.tin
                state.invoice.business.rcNumber = newValue?.rcNumber || state.invoice.business.rcNumber
            }
        )

        const setBusiness = (value) => {
            state.invoice.business.name = value?.org || state.invoice.business.name
            state.invoice.business.address = value?.address || state.invoice.business.address
            state.invoice.business.phone = value?.phone || state.invoice.business.phone
            state.invoice.business.email = value?.email || state.invoice.business.email
            state.invoice.business.country = value?.country || state.invoice.business.country
            state.invoice.business.tin = value?.tin || state.invoice.business.tin
            state.invoice.business.rcNumber = value?.rcNumber || state.invoice.business.rcNumber
        }

          const onNameChange = (value, stateName) => {
            state.invoice.business[stateName] = value.target.value
        }
              onMounted(() => allCountries())
        onMounted(() => {
            setBusiness(orgData.value)
            window.addEventListener('click', (event) => {
                const modalIcon = document.querySelector('.clickModalIcon')

                if (
                    !(event.target.closest('.clickModalIcon') || event.target === modalIcon) &&
                    clickModalInvoice.value === true
                ) {
                    closeModal()
                }
            })

            store.dispatch('Settings/getAccountSettings').then((data) => {
                state.invoice_template = data?.data?.invoice?.template || 'letterhead'
                state.color = data?.data?.invoice?.color || '#132C8C'
                state.font = data?.data?.invoice?.font || 'Arial'
            })
        })

        return {
            isLoading,
            formatAmount,
            formatQuantity,
            getCurrencyName,
            formatDate,
            getDaysFromToday,
            state,
            invoiceFormRef,
            orgImage,
            orgInitials,
            handleDragImage,
            imageElRef,
            handleSelectImage,
            handleClickUpload,
            changeColor,
            openSucker,
            selectTemplate,
            submit,
            skipCustomize,
            orgData,
            clickModalInvoice,
            closeModal,
              sortedCountries,
            selectedCountry,
            onNameChange,
        }
    },
}
</script>

<style>
.btn {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    background: #ffffff;
    color: #132c8c;
    box-sizing: content-box;
}
.directional-up {
    width: 15px;
    height: 15px;
}
.sort1 {
    padding: 6px;
    cursor: pointer;
}
.sort1:hover {
    background-color: rgb(237, 240, 253);
    color: #132c8c;
    border-radius: 5px;
}
@media (max-width: 765px) {
    .flex.bill-section {
        flex-direction: column-reverse;
        align-items: flex-start;
    }
}
.org-img-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(215, 222, 250);
    height: 60px;
    width: 60px;
    border-radius: 50%;
    font-size: 25px;
    color: rgb(19, 44, 140);
    margin-right: 5px;
}
.invoice-status-badge {
    /* background: #FEEAD7;  */
    /* color: #FB8B24;  */
    border-radius: 20px;
}
.share-link-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.share-btn-mobile {
    display: none;
}
.share-btn-desktop {
    display: inline;
}
@media screen and (max-width: 1261px) {
    .share-btn-mobile {
        display: inline;
    }
    .share-btn-desktop {
        display: none;
    }
}
.copy-text-wrapper button {
    border: none !important;
    outline: none !important;
    border-radius: 10px;
    cursor: pointer;
    background-color: unset !important;
    color: unset !important;
}

.copy-text-wrapper button:active {
    background: #809ce2;
}
.copy-text-wrapper button:before {
    content: 'Copied';
    position: absolute;
    top: -45px;
    right: 0px;
    background: #5c81dc;
    padding: 8px 10px;
    border-radius: 20px;
    font-size: 15px;
    display: none;
}
.copy-text-wrapper button:after {
    content: '';
    position: absolute;
    top: -20px;
    right: 25px;
    width: 10px;
    height: 10px;
    background: #5c81dc;
    transform: rotate(45deg);
    display: none;
}
.copy-text-wrapper.active button:before,
.copy-text-wrapper.active button:after {
    display: block;
}
</style>
